import React,{createRef,useEffect} from 'react'
import styles from './noticard.module.css'
import PhNumberTable from './PhNumberTable'
import { FaRegBell,FaPlusCircle } from 'react-icons/fa'
import { ToastContainer, toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"
import Loading from '../common/Loading';
import { useGetSettingDataQuery,useAddPhoneMutation } from '../../features/APIs/settingApi'



const NotiCard = () => {

    const formRef = createRef();
    const { data:getSettingData, isError: getError, isLoading:getLoading } = useGetSettingDataQuery();
    const [addPhone,{error,isError,isLoading,isSuccess}] = useAddPhoneMutation();


    const phoneSchema = yup.object().shape({
        phone_number: yup.string()
        .required('Phone number is required!')
        .matches(/^\d{11}$/, 'Please enter valid phone number!')
        .test('unique', 'Phone number must be unique!', function (value) {
            const existingPhoneNumbers = getSettingData?.data.phones.map((phone) => phone.phone_number);
            return !existingPhoneNumbers || existingPhoneNumbers.indexOf(value) === -1;
        }),
    })

    const { register,handleSubmit,formState: {errors}} = useForm({
        resolver : yupResolver(phoneSchema),
    })

    const handleAdd = (data)=>{
        console.log(data);
        addPhone(data);
        formRef.current.reset();
    }

    useEffect(()=>{
        if(isSuccess){
            toast.success('Phone number is added.', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }else if(error){
            toast.error('Something went wrong!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    },[isSuccess,error])


  return (
    <>
        <div className='col-12 col-lg-6 col-xl-4 mt-3' >
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            {
                isLoading && <Loading></Loading>
            }  
            <div className={`card p-3 shadow-sm ${styles.noticard}`}>
                <div className='bg-white d-flex align-items-start position-sticky top-0'>
                    <FaRegBell className='fs-4'></FaRegBell>
                    <h5 className='card-title fw-bold ms-2'>Notification Management</h5>
                </div>
                <p className='my-3'>Add the phone number you want to send notification.</p>
                <div className='card-body p-0'>
                    <form onSubmit={handleSubmit(handleAdd)} ref={formRef} className="mb-1 mt-3 d-flex justify-content-center align-items-center">
                        <input {...register("phone_number")} type="text" className="form-control me-3" id="phone_number"/>
                        <button className='btn btn-primary text-white text-nowrap'>
                            <FaPlusCircle className='fs-5 me-2'></FaPlusCircle>
                            Add
                        </button>
                    </form>
                    {errors.phone_number && <small className="text-danger">{errors.phone_number.message}</small>}
                    <PhNumberTable></PhNumberTable>
                </div>
            </div>
        </div>
    </>
  )
}

export default NotiCard