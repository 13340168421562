import React,{useContext} from 'react'
import { Navigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';

const Home = () => {

  let { user } = useContext(AuthContext);

  if(user){
    return <Navigate to={"/valid-licenses"}></Navigate>
  }else{
    return <Navigate to={"/login"}></Navigate>
  }
  
}

export default Home