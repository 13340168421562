import React, { useState, Fragment, useEffect,useContext } from 'react';
import { Link } from 'react-router-dom';
import * as Icons from 'react-icons/fa';
import ReactPaginate from 'react-paginate';
import styles from './table.module.css';
import {
    useGetAllInvalidLicensesQuery,
    useInvalidLicenseByDateMutation,
    useGetSearchedLicensesByPageInvalidMutation,
    useDateFilterByPageInvalidMutation,
    useGetLicensesByPageInvalidMutation,
    useGetLicenseBySearchInvalidMutation,
    useSearchedByDateBetweenInvalidMutation,
    useSearchedDateBetweenByPageInvalidMutation,
    useDeleteLicenseInvalidMutation
} from '../../features/APIs/invalidLicensesApi';
import DofGoodModal from '../common/modals/DofGoodModal';
import SweetAlert from 'react-bootstrap-sweetalert';
import Loading from '../common/Loading';
import { ToastContainer, toast } from 'react-toastify';
import AuthContext from '../../context/AuthContext';

const Table = ({ startDate,endDate,searchText }) => {
        const { logOut } = useContext(AuthContext);
        const [deleteLicense,{ isLoading: delLoading, isSuccess: delSuccess}] = useDeleteLicenseInvalidMutation();
        const { data: getAllLicenses, error: getError, isLoading: allLicensesLoading, isSuccess, refetch } = useGetAllInvalidLicensesQuery();
        const [ licenseByDate, { isLoading: byDateLoading} ] = useInvalidLicenseByDateMutation();
        const [ getLicensesBySearch,{ isLoading: searchLoading } ] = useGetLicenseBySearchInvalidMutation();
        const [ searchedByDateBetween,{ isLoading: searchedByDateBetweenLoading }] = useSearchedByDateBetweenInvalidMutation();
        //pagination
        const [ getLicensesByPage, { isLoading: byPageLoading } ] = useGetLicensesByPageInvalidMutation();
        const [ searchedDateBetweenByPage, { isLoading: useSearchedDateBetweenByPageLoading }] = useSearchedDateBetweenByPageInvalidMutation();
        const [ getSearchedLicensesByPage, { isLoading: bySearchedPageLoading } ] = useGetSearchedLicensesByPageInvalidMutation();
        const [ dateFilterByPage, { isLoading: dateFilterByPageLoading, refetch: byPageRefetch } ] = useDateFilterByPageInvalidMutation();
        const [dofGoodForModal, setDOfGood] = useState('');
        const [ allLicenses, setAllLicenses ] = useState([]);
        const [showAlert, setShowAlert] = useState(false);
        const [idToDelete, setIdToDelete] = useState(null);
        const [ countOfPage,setCountOfPage ] = useState(0);
        const [ user,setUsesr ] = useState(JSON.parse(localStorage.getItem('authToken')).user)
        const [ pageNo,setPageNo ]= useState();

    const tableHeaders = [
      "#",
      "license no",
      "company name",
      "license start date",
      "license expired date",
      "license invalid date",
      "hs code",
      "maccs code",
      "no",
      "description of goods",
      "price",
      "quantity",
      "unit",
      "actions"
    ];
  
    const seemore = (content) => {
      setDOfGood(content);
    };
  
    const handleDelete = (id) => {
      setShowAlert(true);
      setIdToDelete(id);
    };
  
    const confirmForDelete = async () => {
      deleteLicense(idToDelete);
      setShowAlert(true);
      await cancelForDelete();
    };
  
    const cancelForDelete = () => {
      setIdToDelete(null);
      setShowAlert(false);
    };

    const handlePageClick = (e)=>{
        const pageNo = e.selected + 1;
        setPageNo(pageNo)
        localStorage.setItem("invalidPageNo",pageNo);
        if(startDate && endDate && searchText == ""){
            dateFilterByPage({ startDate,endDate,pageNo }).unwrap()
            .then((data)=> {
                setAllLicenses(data.data)
            })
            .catch((error)=> { throw new Error(error) })
        }else if((startDate == null && endDate == null) && searchText !== ""){
            getSearchedLicensesByPage({searchText,pageNo}).unwrap()
            .then((data)=> {
                setAllLicenses(data.data)
            })
            .catch((error)=> {
                toast.error('Something went wrong!', {
                    position: 'top-right',
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });
            })
        }else if(startDate && endDate && searchText){
            searchedDateBetweenByPage({startDate,endDate,searchText,pageNo}).unwrap()
            .then((data)=> {
                setAllLicenses(data.data)
            })
            .catch((error)=> {
                toast.error('Something went wrong!', {
                    position: 'top-right',
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });
             })
        }else if(startDate == null && endDate == null && searchText == ""){
            if(getAllLicenses){
                getLicensesByPage(pageNo).unwrap()
                .then((data)=> {
                    setAllLicenses(data.data)

                })
                .catch((error)=> {
                    toast.error('Something went wrong!', {
                    position: 'top-right',
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });
                 })
            }
        }
    }

    useEffect(()=>{
        if(startDate && endDate && searchText == ""){
            licenseByDate({ startDate,endDate }).unwrap()
            .then((data)=> {
                setAllLicenses(data.data)
            })
            .catch((error)=> {
                toast.error('Something went wrong!', {
                    position: 'top-right',
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });
             })
        }else if((startDate == null && endDate == null) && searchText !== ""){
            getLicensesBySearch(searchText).unwrap()
            .then((data)=> {
                setAllLicenses(data.data)
            })
            .catch((error)=> {
                toast.error('Something went wrong!', {
                    position: 'top-right',
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });
             })
        }else if(startDate && endDate && searchText){
            searchedByDateBetween({startDate,endDate,searchText}).unwrap()
            .then((data)=> {
                setAllLicenses(data.data)
            })
            .catch((error)=> {
                toast.error('Something went wrong!', {
                    position: 'top-right',
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });
             })
        }else if(startDate == null && endDate == null && searchText == ""){
            if(getAllLicenses){
                setAllLicenses(getAllLicenses.data);
                setCountOfPage(Math.ceil(getAllLicenses.meta?.total/getAllLicenses.meta?.count));
            }
        }
    },[startDate,endDate,searchText])

    const getNo = (index)=>{
        const noFromLocal = localStorage.getItem('invalidPageNo');
        if(noFromLocal &&  noFromLocal > 1){
            return (noFromLocal -1)*5+ (index + 1)
        }else if(noFromLocal <= 1){
            return index + 1
        }
    }


    useEffect(()=>{
        if(getAllLicenses){
            setAllLicenses(getAllLicenses.data);
            setCountOfPage(Math.ceil(getAllLicenses.meta?.total/getAllLicenses.meta?.count));
        }else if(getError){
            console.log(getError);
        }
    },[getAllLicenses])

    useEffect(()=>{
        if (delSuccess) {
          refetch();
          if(delSuccess){
            toast.success('Successfully deleted.', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            }
        }          
    },[delSuccess])

    useEffect(()=>{
        if(localStorage.getItem('invalidPageNo')){
            getLicensesByPage(localStorage.getItem('invalidPageNo')).unwrap()
            .then((data)=> {
                setAllLicenses(data.data)

            })
            .catch((error)=> {
                toast.error('Something went wrong!', {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
            })
        }
    },[])

    useEffect(()=>{
        if(getError && getError.status === 401){
            toast.error(getError.data.message, {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
            logOut();
        }
    },[getError])


   
  return  allLicensesLoading ?
            <Loading></Loading>
            :
            <>
                {                     
                    ( byPageLoading || byDateLoading || searchLoading || searchedByDateBetweenLoading || useSearchedDateBetweenByPageLoading || bySearchedPageLoading || dateFilterByPageLoading)  && <Loading />
                }
                 <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                />
                <div className="table-responsive">                
                    {
                        showAlert &&
                        <SweetAlert
                        showCancel
                        confirmBtnText="Yes, delete it!"
                        confirmBtnBsStyle="danger"
                        title="Are you sure?"
                        onConfirm={confirmForDelete}
                        onCancel={cancelForDelete}
                        focusCancelBtn
                        >
                        You want to delete this license record!
                        </SweetAlert>
                    }
                    <DofGoodModal content={dofGoodForModal}></DofGoodModal>
            <table className={`table table-hover`}>
                <thead className="align-top">
                    <tr>
                    {
                        tableHeaders.map((el,index)=>{
                        return <th key={index} className={`text-center bg-secondary ${index === 0 ? styles.firstHead : ""} ${index === 11 ? styles.lastHead : ""}`}>{el.toUpperCase()}</th>
                        })
                    }
                    </tr>
                </thead>

                <tbody className='align-middle'>
                    {
                        allLicenses && allLicenses.length === 0 ?
                        <tr>
                            <td colSpan={14} className='p-0 border-0'>
                                <div className="alert alert-warning text-center mb-0" role="alert">
                                    There is no record for invalid licenses!
                                </div>
                            </td>
                        </tr>
                        :
                        allLicenses.map((el,i)=>{
                            return <Fragment key={i}>
                                    <tr>
                                        <td className='text-center'>{getNo(i)}</td>
                                        <td className='text-center'>{el.license_no}</td>
                                        <td className='text-center'>{el.company_name}</td>
                                        <td className='text-center text-nowrap'>{el.license_start_date}</td>
                                        <td className='text-center text-nowrap'>{el.license_expired_date}</td>
                                        <td className='text-center text-nowrap'>{el.license_invalid_date}</td>
                                        {
                                            el.good &&
                                                <Fragment>
                                                    <td className='text-center'>{el.good[0].hs_code}</td>
                                                    <td className='text-center'>{el.good[0].maccs_code}</td>
                                                    <td className='text-center'>1</td>
                                                    <td>
                                                        {el.good[0].name.substring(0,100)}...
                                                        <a
                                                        className='text-primary text-decoration-none'
                                                        data-bs-target="#exampleModalToggle"
                                                        data-bs-toggle="modal"
                                                        onClick={()=>{
                                                            seemore(el.good[0].name)
                                                        }}
                                                        >see more</a>
                                                    </td>
                                                    <td className='text-center'>{el.good[0].price}</td>
                                                    <td className='text-center'>{el.good[0].license_balance}</td>
                                                    <td className='text-center'>{el.good[0].unit}</td>
                                                    <td className='text-nowrap'>
                                                        <Link to={`/invalid-licenses-detail/${el.id}`} className='btn btn-dark btn-sm text-white me-2'>Details</Link>
                                                        {
                                                            user.is_admin == 1 &&
                                                            <button onClick={()=>{handleDelete(el.id)}} className='btn btn-danger btn-sm text-white'>Delete</button>
                                                        }
                                                    </td>
                                            </Fragment>

                                        }
                                    </tr>
                                    {
                                        el.good.length > 1 &&
                                        el.good.slice(1).map((eachGood,i)=>{
                                            return <tr key={i}>
                                                        <td className='text-center' colSpan={6}></td>
                                                        <td className='text-center'>{eachGood.hs_code}</td>
                                                        <td className='text-center'>{eachGood.maccs_code}</td>
                                                        <td className='text-center'>{i+2}</td>
                                                        {
                                                            eachGood.name.length > 100 ?
                                                            <td>
                                                                {eachGood.name.substring(0,100)}...
                                                                <a
                                                                className='text-primary text-decoration-none text-nowrap'
                                                                data-bs-target="#exampleModalToggle"
                                                                data-bs-toggle="modal"
                                                                onClick={()=>{
                                                                    seemore(eachGood.name)
                                                                }}
                                                                >see more</a>
                                                            </td>:
                                                            <td>
                                                                {eachGood.name}
                                                            </td>
                                                        }
                                                        <td className='text-center'>{eachGood.price}</td>
                                                        <td className='text-center'>{eachGood.quantity}</td>
                                                        <td className='text-center'>{eachGood.unit}</td>
                                                        <td></td>
                                                    </tr>
                                        })
                                    }
                                </Fragment>
                        })
                    }
                </tbody>
                </table>
                {
                    countOfPage > 0 &&
                        <ReactPaginate
                        className={`pagination justify-content-end mb-0 w-25 ms-auto ${styles.myPagination}`}
                        pageClassName= "page-item"
                        pageLinkClassName='page-link'
                        breakLabel="..."
                        nextLabel={<Icons.FaAngleRight/>}
                        peviousClassName= "page-item"
                        previousLinkClassName= "page-link"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={countOfPage}
                        previousLabel={<Icons.FaAngleLeft/>}
                        nextClassName= "page-item"
                        nextLinkClassName= "page-link"
                        renderOnZeroPageCount={null}
                        activeClassName='active'
                        forcePage={localStorage.getItem('invalidPageNo') ? localStorage.getItem('invalidPageNo')-1 : 0} 
                    />
                }
            </div>
            </>
}

export default Table