import { apiSlice } from "./apiSlice";

export const settingApi = apiSlice.injectEndpoints({
    endpoints: (builder)=>({
        getSettingData: builder.query({
            query: ()=>({
                url: "/settings",
                method: "GET"
            }),
            providesTags: ["settings"]
        }),
        addPhone: builder.mutation({
            query: (phNumber)=>({
                url: "/settings/phones/store",
                method: "POST",
                body: phNumber
            }),
            invalidatesTags: ["settings"]
        }),
        deletePhone: builder.mutation({
            query: (id)=>({
                url: `/settings/phones/delete/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: ['settings']
        }),
        addUser: builder.mutation({
            query: (user) => ({
              url: "/settings/users/store",
              method: "POST",
              body: user,
            }),
            invalidatesTags: ["settings"],
          }),
        deleteUser: builder.mutation({
            query: (id)=>({
                url: `/settings/users/delete/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: ["settings"]
        }),
    })
})


export const {
    useGetSettingDataQuery,
    useDeleteUserMutation,
    useAddPhoneMutation,
    useDeletePhoneMutation,
    useAddUserMutation
} = settingApi;