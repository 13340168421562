import React,{useEffect, useState} from 'react'
import styles from './phNumsTable.module.css';
import { FaRegTrashAlt } from 'react-icons/fa'
import Loading from '../common/Loading';
import { ToastContainer, toast } from 'react-toastify';
import { useGetSettingDataQuery,useDeletePhoneMutation } from '../../features/APIs/settingApi';

import SweetAlert from 'react-bootstrap-sweetalert';

const PhNumberTable = () => {

    const [deletePhone, { isLoading: delLoading, isSuccess: delSuccess}] = useDeletePhoneMutation();
    const [allPhones,setAllPhones] = useState([]);
    const { data:getData, error, isError, isLoading } = useGetSettingDataQuery();
    const [ showAlert,setShowAlert ] = useState(false);
    const [ idToDelete,setIdToDelete] = useState(null);

    const handleDelete = (id)=>{
        setShowAlert(true);
        setIdToDelete(id);
    }
    const confirmForDelete = async ()=>{
        deletePhone(idToDelete);
        setShowAlert(true);
        await cancelForDelete();
    }
    const cancelForDelete = ()=>{
        setIdToDelete(null);
        setShowAlert(false);
    }
    

    useEffect(()=>{
        if(getData){
            setAllPhones(getData.data.phones)
        }
      },[getData,deletePhone])

    useEffect(()=>{
        if(delSuccess){
            toast.success('Successfully deleted.', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    },[delSuccess])

  return (
    <>
             <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        {
          showAlert &&
            <SweetAlert
              showCancel
              confirmBtnText="Yes, delete it!"
              confirmBtnBsStyle="danger"
              title="Are you sure?"
              onConfirm={confirmForDelete}
              onCancel={cancelForDelete}
              focusCancelBtn
            >
            You want to delete this phone number!
            </SweetAlert>
        }
        {
            delLoading && <Loading></Loading>
        }
        {
            allPhones.length > 0 ?
            <table className={`w-100 table border mt-3 ${styles.phNumberTable}`}>
                <tbody>
                    {
                        allPhones &&
                        allPhones.map((el,i)=>{
                            return <tr key={i}>
                                        <td className='fs-16 ps-3'>{el.phone_number}</td>
                                        <td className='text-center'>
                                            <FaRegTrashAlt onClick={()=>{handleDelete(el.id)}} className={`fs-20 text-danger ${styles.trash}`}></FaRegTrashAlt>
                                        </td>
                                    </tr>
                        })
                    }
                </tbody>
            </table>
            :
            <div className="alert alert-warning mt-3 text-center" role="alert">
                There is no record!
            </div>
            }
    </>
  )
}

export default PhNumberTable