import React, { useEffect, useState,useContext } from 'react'
import styles from './table.module.css'
import { useGetSettingDataQuery,useDeleteUserMutation } from '../../features/APIs/settingApi';
import SweetAlert from 'react-bootstrap-sweetalert';
import { ToastContainer, toast } from 'react-toastify';
import Loading from '../common/Loading'
import AuthContext from '../../context/AuthContext';

const Table = () => {
  const { logOut } = useContext(AuthContext);
  const [deleteUser, {isLoading: delLoading, isSuccess: delSuccess}] = useDeleteUserMutation();
  const { data:getSettingData,error ,isLoading } = useGetSettingDataQuery(
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const [ allUser,setAllUser ] = useState([]);
  const [ showAlert,setShowAlert ] = useState(false);
  const [ idToDelete,setIdToDelete] = useState(null);

  const authUserId = JSON.parse(localStorage.getItem('authToken')).user.id;

  const handleDelete = (id)=>{
    setShowAlert(true);
    setIdToDelete(id);
  }

  const confirmForDelete = async ()=>{
    deleteUser(idToDelete);
    setShowAlert(true);
    await cancelForDelete();
  }

  const cancelForDelete = ()=>{
    setIdToDelete(null);
    setShowAlert(false);
  }

  useEffect(()=>{
    if(getSettingData){
      const allUserWithoutCurrentUser = getSettingData.data.all_users.filter((el)=> el.id !== authUserId);
      setAllUser(allUserWithoutCurrentUser)
    }
  },[getSettingData,deleteUser,authUserId])

  useEffect(()=>{
    if(delSuccess){
      toast.success('Successfully deleted.', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
      });
  }
  },[delSuccess])

  useEffect(()=>{
    if(error && error.status === 401){
      toast.error(error.data.message, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      logOut();
    }
  },[])

  return (
    <>
              <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        {
          showAlert &&
            <SweetAlert
              showCancel
              confirmBtnText="Yes, delete it!"
              confirmBtnBsStyle="danger"
              title="Are you sure?"
              onConfirm={confirmForDelete}
              onCancel={cancelForDelete}
              focusCancelBtn
            >
            You want to delete this user!
            </SweetAlert>
        }
        {
          delLoading || isLoading && <Loading></Loading>
        }

        <div className='col-12 mt-5'>
          <table className={`table w-100`}>
                <thead className={`text-center position-sticky top-0 ${styles.mytHead}`}>
                  <tr>
                    <th className={`bg-secondary ${styles.firstHead}`} scope="col">#</th>
                    <th className='bg-secondary' scope="col">USERNAME</th>
                    <th className='bg-secondary' scope="col">EMAIL</th>
                    <th className='bg-secondary' scope="col">ROLE</th>
                    <th className={`bg-secondary ${styles.lastHead}`} scope="col">ACTIONS</th>
                  </tr>
                </thead>
                <tbody className={`align-middle ${styles.mytBody}`}>
                  {
                    (allUser && allUser.length > 0) ?
                    allUser.map((el,index)=>{
                          return  <tr key={index}>
                                    <td className='text-center'>{el.id}</td>
                                    <td className='text-center'>{el.name}</td>
                                    <td className='text-center'>{el.email}</td>
                                    <td className='text-center'>
                                      {
                                        el.is_admin == 1 ?
                                        <span className="badge rounded-pill text-bg-success text-white">Admin</span>
                                        :
                                        <span className="badge rounded-pill text-bg-primary text-white">User</span>
                                      }
                                    </td>
                                    <td className='text-center'>
                                      <button onClick={()=>{ handleDelete(el.id) }} className='btn btn-danger btn-sm text-white'>Delete</button>
                                    </td>
                                  </tr>
                        })
                        :
                        <tr>
                            <td colSpan={14} className='p-0 border-0'>
                                <div className="alert alert-warning text-center mb-0" role="alert">
                                    There is no record for users!
                                </div>
                            </td>
                        </tr>
                      }
                </tbody>
          </table>
      </div>
    </>

  )
}

export default Table