import React,{useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import Loading from '../Loading';
import { useGetSingleLicenseQuery,useRenewLicenseMutation } from '../../../features/APIs/validLicensesApi';


const RenewModal = ({ id }) => {
    const nav = useNavigate("/");
    const [endDate, setEndDate] = useState(null);
    const [close,setClose] = useState(false);
    const { data: getLicense, isLoading, isSuccess, refetch } = useGetSingleLicenseQuery(id, {
        refetchOnMountOrArgChange: true,
    });
    const [renewLicense, { isLoading: isUpdating, isSuccess: renewSuccess }] = useRenewLicenseMutation();

    const changeDateFormat = (inputDate)=>{
        const toChange = new Date(inputDate);
        const year = toChange.getFullYear();
        const month = String(toChange.getMonth() + 1).padStart(2, '0');
        const day = String(toChange.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
      }

    const handleRenew = async ()=>{
        refetch();
        if (!endDate) {
            toast.error('Please select an expiration date!', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            });
            return;
        }
        const data = {
            license_expired_date: changeDateFormat(endDate),
        };
        
        await renewLicense({ id: id, date: data })
        .unwrap()
        .then(async () => {
            await toast.success('Successfully renewed', {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
            refetch();
        })
        .catch((error) => {
            toast.error('Something went wrong!', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
        });
    }

    useEffect(()=>{
        if(isSuccess){
            const [year, month, day] = getLicense.data.license_expired_date.split('-');
            const parsedDate = new Date(year, month - 1, day);
            setEndDate(parsedDate);
        }
    },[getLicense,close])

    useEffect(()=>{
        if(renewSuccess){
            nav("/");
        }
    },[renewSuccess])


  return (
    <>
        <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
        />
        {
           isLoading || isUpdating && <Loading></Loading>
        }
        <div className="modal fade" id="renewModalToggle" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="renewModalToggleLabel">Renew License</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>{ setClose((prev)=>!prev)}}></button>
                    </div>
                    <div className="modal-body text-wrap">
                        <div className='d-flex align-items-center my-3'>
                            <label className='text-nowrap mx-3'>Expired Date:</label>
                            <DatePicker className='form-control'
                                dateFormat="yyyy-MM-dd" isClearable showYearDropdown
                                name='license_expired_date'
                                selected={endDate}
                                onChange={
                                (date) =>{
                                    setEndDate(date)
                            }} />
                        </div>
                        <div className='d-flex justify-content-end mt-3 mb-2'>
                            <button className='btn btn-outline-dark me-2' data-bs-dismiss="modal" onClick={()=>{ setClose((prev)=>!prev) }}>Close</button>
                            <button className='btn btn-primary text-white' onClick={(()=>{handleRenew()})} data-bs-dismiss="modal">Renew</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

  


export default RenewModal