import React,{useState,useEffect} from 'react'
import { useGetSingleLicenseQuery } from '../../features/APIs/validLicensesApi'
import styles from './detailDOfGood.module.css'

const DetailDOfGood = ({id,goodId}) => {

    const [ dOfGood,setDofGood ] = useState({});
    const { data: getLicense } = useGetSingleLicenseQuery(id, {
        refetchOnMountOrArgChange: true,
      });


    useEffect(()=>{
        if(getLicense && goodId){
            setDofGood(getLicense.data.good.filter((good)=> good.id === goodId));
        }
    },[getLicense,goodId,dOfGood.license_balance])


  return (
    <>
            <table className='table table-borderless m-2'>
                <thead>
                    <tr>
                        <th className={`bg-secondary ${styles.dOfGoodTh} ${styles.otherTh}`}>Description Of Goods</th>
                        <th className={`bg-secondary text-center ${styles.otherTh}`}>Hs Code</th>
                        <th className={`bg-secondary text-center ${styles.otherTh}`}>Price</th>
                        <th className={`bg-secondary text-center ${styles.otherTh}`}>Qty</th>
                        <th className={`bg-secondary text-center ${styles.otherTh} text-nowrap`}>License Balance</th>
                        <th className={`bg-secondary text-center ${styles.otherTh}`}>Unit</th>
                    </tr>
                </thead>
                <tbody className={`${styles.mytBody}`}>
                    {
                        dOfGood.length > 0 &&
                        <tr>
                            <td className='bg-secondary'>{dOfGood[0].name}</td>
                            <td className='bg-secondary text-center'>{dOfGood[0].hs_code}</td>
                            <td className='bg-secondary text-center'>{dOfGood[0].price}</td>
                            <td className='bg-secondary text-center'>{dOfGood[0].quantity}</td>
                            <td className='bg-secondary text-center'>{dOfGood[0].license_balance}</td>
                            <td className='bg-secondary text-center'>{dOfGood[0].unit}</td>
                        </tr>
                    }
                </tbody>
            </table>
    </>
  )
}

export default DetailDOfGood