import { apiSlice } from "./apiSlice";

export const taxApi = apiSlice.injectEndpoints({
    endpoints: (builder)=>({
        getAllTax: builder.query({
            query: ()=>({
                url: "/licenses/transactions",
                method: "GET"
            }),
            providesTags: ["transactions"]
        }),
        taxByGoodId: builder.mutation({
            query: (goodId)=>({
                url: `/licenses/transactions/index?good_id=${goodId}`,
                method: "GET",
            }),
            invalidatesTags: ['transactions']
        }),
        getTaxByGoodId: builder.query({
            query: (goodId)=>({
                url: `/licenses/transactions/index?good_id=${goodId}`,
                method: "GET"
            }),
            providesTags: ["transactions"]
        }),
        getTaxByTaxId: builder.query({
            query: (taxId)=>({
                url: `/licenses/transactions/${taxId}`,
                method: "GET",
            }),
            providesTags: ["transactions"]
        }),
        addTax: builder.mutation({
            query: (tax)=>({
                url: "/licenses/transactions/store",
                method: "POST",
                body: tax
            }),
            invalidatesTags: ["transactions"]
        }),
        updateTax: builder.mutation({
            query: ({id,data})=>({
                url: `/licenses/transactions/update/${id}`,
                method: "PUT",
                body: data
            }),
            invalidatesTags: ["transactions"]
        }),
        deleteTax: builder.mutation({
            query: (id)=>({
                url: `/licenses/transactions/delete/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["transactions"]
        }),
    })
})

export const {
    useGetAllTaxQuery,
    useTaxByGoodIdMutation,
    useGetTaxByGoodIdQuery,
    useGetTaxByTaxIdQuery,
    useUpdateTaxMutation,
    useAddTaxMutation,
    useDeleteTaxMutation
 } = taxApi;