import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { ToastContainer, toast } from 'react-toastify';
import { useGetTaxByTaxIdQuery, useUpdateTaxMutation } from '../../../features/APIs/taxApi';
import Loading from '../../common/Loading';


const EditTransModal = ({ goodId, taxId }) => {

  const [idDate, setIdDate] = useState(new Date());
  const { data: getTax, refetch } = useGetTaxByTaxIdQuery(taxId);
  const [updateTax, { isLoading , isSuccess, data, error }] = useUpdateTaxMutation();
  const [defaultFormValues, setDefaultFormValues] = useState({});

  const transactionSchema = yup.object().shape({
    transaction_id: yup.string(),
    id_date: yup.date().typeError("Id date must be a date!").required("Id date is required!"),
    quantity: yup.number().required("Quantity is required!")
  });

  const { register, handleSubmit, reset, formState: { errors }, setValue } = useForm({
    resolver: yupResolver(transactionSchema),
    defaultValues: defaultFormValues,
  });

  const changeDateFormat = (inputDate)=>{
    const toChange = new Date(inputDate);
    const year = toChange.getFullYear();
    const month = String(toChange.getMonth() + 1).padStart(2, '0');
    const day = String(toChange.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
    }

  const handleOnSubmit = async (data) => {
    const updateData = {
        good_id: goodId,
        transaction_id: data.transaction_id,
        id_date: changeDateFormat(idDate),
        quantity: data.quantity,
    }
    updateTax({ id: taxId , data:updateData })
    await toast.success('Successfully updated', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
    });
  }

  useEffect(() => {
    if (getTax) {
      setIdDate(new Date(getTax.data.id_date));
      setValue('id_date', idDate);
      setValue('transaction_id', getTax.data.transaction_id);
      setValue('quantity', getTax.data.quantity);
      setDefaultFormValues({
        good_id: goodId,
        transaction_id: getTax.data.transaction_id,
        id_date: getTax.data.id_date,
        quantity: getTax.data.quantity
      });
    } else if (error) {
      toast.error('You need to try again later!', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      window.location.reload();
    }
  }, [getTax]);

  return (
    <>
      { isLoading && <Loading></Loading>}
      <div className="modal fade" id="editModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="staticBackdropLabel">Edit Tax</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <form onSubmit={handleSubmit(handleOnSubmit)} className="modal-body">
            <div className='d-flex align-items-center my-3'>
              <label className='text-nowrap mx-3'>Id No:</label>
              <div className='w-75 ms-auto'>
                <input {...register('transaction_id')} name='transaction_id' defaultValue={defaultFormValues.transaction_id} className='form-control'></input>
                <small className='text-danger w-75'>{errors.transaction_id?.message}</small>
              </div>
            </div>
            <div className='d-flex align-items-center my-3'>
              <label className='text-nowrap mx-3'>Id Date:</label>
              <div className='w-75 ms-auto'>
                <DatePicker className='form-control'
                  {...register('id_date')}
                  dateFormat="yyyy-MM-dd" isClearable showYearDropdown
                  name='id_date'
                  selected={idDate}
                  onChange={
                    (date) => {
                      setIdDate(date);
                    }}
                />
                <small className='text-danger'>{errors.id_date?.message}</small>
              </div>
            </div>
            <div className='d-flex align-items-center my-3'>
              <label className='text-nowrap mx-3'>Id Qty:</label>
              <div className='w-75 ms-auto'>
                <input {...register("quantity")} defaultValue={defaultFormValues.quantity} name='quantity' className='form-control'></input>
                <small className='text-danger'>{errors.quantity?.message}</small>
              </div>
            </div>
            <div className='mt-4 mb-3 d-flex align-items-center justify-content-end'>
              <button type="button" className="btn btn-outline-dark me-2" data-bs-dismiss="modal">Close</button>
              <button type="submit" className="btn btn-primary text-white" data-bs-dismiss="modal">Update</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    </>
  )
}

export default EditTransModal;
