import React,{useEffect, useState, createRef} from 'react'
import DatePicker from "react-datepicker";
import { useForm,Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup"
import { useAddTaxMutation } from '../../../features/APIs/taxApi';
import Loading from '../../common/Loading';
import { ToastContainer, toast } from 'react-toastify';

const CreateTransModal = ({goodId}) => {
    const formRef = createRef();
    const [addTax,{ isSuccess, isLoading }] = useAddTaxMutation();
    const [defaultFormValues, setDefaultFormValues] = useState({
        transaction_id: "",
        id_date: new Date(),
        quantity: ""
    });

    const transactionSchema = yup.object().shape({
        transaction_id: yup.string().required("Transaction id is required!"),
        id_date: yup.date().typeError("Id date must be a date!").required("Id date is required!"),
        quantity: yup.number().typeError("Quantity must be a number!").positive("Quantity is invalid").required("Quantity is required!")
    });

    const { control,handleSubmit,reset,formState: {errors}} = useForm({
        resolver : yupResolver(transactionSchema),
        defaultValues: defaultFormValues,
    })
    const changeDateFormat = (inputDate)=>{
        const toChange = new Date(inputDate);
        const year = toChange.getFullYear();
        const month = String(toChange.getMonth() + 1).padStart(2, '0');
        const day = String(toChange.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    }

    const onSubmit = (data)=>{
        const taxData = {
            good_id: goodId,
            transaction_id: data.transaction_id,
            id_date: changeDateFormat(data.id_date),
            quantity: data.quantity
        };
        addTax(taxData);
        setDefaultFormValues({
            transaction_id: "",
            id_date: new Date(),
            quantity: ""
        });
        reset(defaultFormValues);
    }

    useEffect(() => {
        reset(defaultFormValues);
    }, [defaultFormValues, reset]);

    useEffect(()=>{
        if(isSuccess){
            toast.success("Successfully created.", {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        }
    },[isSuccess])

  return (
    <>
        {
            isLoading && <Loading></Loading>
        }
        <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
            <div className="modal-header">
                <h1 className="modal-title fs-5" id="staticBackdropLabel">Create New Tax</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
                <form ref={formRef} onSubmit={ handleSubmit(onSubmit) } className="modal-body">
                    <div className='d-flex align-items-center my-3'>
                        <label className='text-nowrap mx-3'>Id No:</label>
                        <div className='w-75 ms-auto'>
                            <Controller
                                control={control}
                                name="transaction_id"
                                render={({ field }) => (
                                    <input {...field} className='form-control'></input>
                            )}
                            />
                            <small className='text-danger w-75'>{errors.transaction_id?.message}</small>
                        </div>
                    </div>
                    <div className='d-flex align-items-center my-3'>
                        <label className='text-nowrap mx-3'>Id Date:</label>
                        <div className='w-75 ms-auto'>
                            <Controller
                                control={control}
                                name="id_date"
                                render={({ field }) => (
                                    <DatePicker
                                    className='form-control ms-auto'
                                    dateFormat='yyyy-MM-dd'
                                    isClearable
                                    showYearDropdown
                                    {...field}
                                    selected={field.value}
                                    onChange={(date) => field.onChange(date)}
                                    />
                            )}
                            />
                            <small className='text-danger'>{errors.id_date?.message}</small>
                        </div>
                    </div>
                    <div className='d-flex align-items-center my-3'>
                        <label className='text-nowrap mx-3'>Id Qty:</label>
                        <div className='w-75 ms-auto'>
                            <Controller
                                control={control}
                                name="quantity"
                                render={({ field }) => (
                                    <input {...field} className='form-control'></input>
                            )}
                            />
                            <small className='text-danger'>{errors.quantity?.message}</small>
                        </div>
                    </div>
                    <div className='mt-4 mb-3 d-flex align-items-center justify-content-end'>
                        <button type="button" className="btn btn-outline-dark me-2" data-bs-dismiss="modal">Close</button>
                        <button type="submit" className="btn btn-primary text-white" data-bs-dismiss="modal">Create</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    </>
  )
}

export default CreateTransModal