import { apiSlice } from "./apiSlice";


export const validLicensesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllValidLicenses: builder.query({
        query: ()=>({
            url: "/licenses?type=valid",
            method: "GET"
        }),
        providesTags: ["valid-licenses"],
    }),
    getSingleLicense: builder.query({
      query: (id) => ({
        url: `/licenses/${id}`,
        method: "GET",
      }),
      providesTags: ["valid-licenses"],
    }),
    licenseByDate: builder.mutation({ // date filter
      query: ({ startDate,endDate })=>({
        url: `/licenses/valid-between?start_date=${startDate}&end_date=${endDate}`,
        method: "GET"
      }),
      invalidatesTags: ['valid-licenses']
    }),
    searchedByDateBetween: builder.mutation({ // search between date filter
      query: ({startDate,endDate,searchText})=>({
        url: `/licenses/valid-search-between?query=${searchText}&start_date=${startDate}&end_date=${endDate}`,
        method: "GET"
      }),
      invalidatesTags: ["valid-licenses"]
    }),
    searchedDateBetweenByPage: builder.mutation({// search between date filter
      query: ({ startDate,endDate,searchText,pageNo})=>({
        url: `/licenses/valid-search-between?query=${searchText}&start_date=${startDate}&end_date=${endDate}&page=${pageNo}`,
        method: "GET"
      }),
      invalidatesTags: ["valid-licenses"]
    }),
    dateFilterByPage: builder.mutation({ //date filter pagination
      query: ({ startDate,endDate,pageNo })=>({
        url: `/licenses/valid-between?start_date=${startDate}&end_date=${endDate}&page=${pageNo}`,
        method: "GET",
      }),
      invalidatesTags: ["valid-licenses"]
    }),
    getLicensesByPage: builder.mutation({ //pagination for all valid licenses
      query: (pageNo)=>({
        url: `/licenses?type=valid&page=${pageNo}`,
        method: "GET"
      }),
      invalidatesTags: ["valid-licenses"]
    }),
    getLicenseBySearch: builder.mutation({//search
      query: (searchText)=>({
        url: `/licenses/valid-search?query=${searchText}`,
        method: "GET"
      }),
      invalidatesTags: ['valid-licenses']
    }),
    getSearchedLicensesByPage: builder.mutation({//searched licenses pagination
      query: ({ searchText,pageNo })=>({
        url: `/licenses/valid-search?query=${searchText}&page=${pageNo}`,
        method: "GET",
      }),
      invalidatesTags: ["valid-licenses"]
    }),
    addLicense: builder.mutation({
      query: (license) => ({
        url: "/licenses/store",
        method: "POST",
        body: license,
      }),
      invalidatesTags: ["valid-licenses"],
    }),
    deleteLicense: builder.mutation({
      query: (id) => ({
        url: `/licenses/delete/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: ["valid-licenses"],
    }),
    deleteGood: builder.mutation({
      query: (id)=>({
        url: `/licenses/delete-good/${id}`,
        method: "DELETE",
        body: id
      }),
      invalidatesTags: ['valid-licenses']
    }),
    updateLicense: builder.mutation({
      query: ({ id, data }) => ({
        url: `/licenses/update/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["valid-licenses"],
    }),
    renewLicense: builder.mutation({
      query: ({ id,date })=>({
        url: `/licenses/renew/${id}`,
        method: "PUT",
        body: date 
      }),
      invalidatesTags: ["valid-licenses"],
    }),
  }),
});

export const {
  useGetAllValidLicensesQuery,
  useGetSingleLicenseQuery,
  useLicenseByDateMutation,
  useGetSearchedLicensesByPageMutation,
  useDateFilterByPageMutation,
  useGetLicensesByPageMutation,
  useGetLicenseBySearchMutation,
  useSearchedByDateBetweenMutation,
  useSearchedDateBetweenByPageMutation,
  useAddLicenseMutation,
  useDeleteLicenseMutation,
  useDeleteGoodMutation,
  useUpdateLicenseMutation,
  useRenewLicenseMutation
} = validLicensesApi;