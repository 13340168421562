import React, { useEffect, useState } from 'react'
import styles from './taxTable.module.css'
import { useTaxByGoodIdMutation } from '../../../features/APIs/taxApi'
import Loading from '../../common/Loading';
import { ToastContainer, toast } from 'react-toastify';

const TaxTable = ({ goodId }) => {

  const [taxs,setTaxs] = useState([]);
  const [ getTaxs, { isLoading }] = useTaxByGoodIdMutation(goodId);


  useEffect(()=>{
    getTaxs(goodId).unwrap()
    .then((data)=>{
      setTaxs(data.data);
    })
    .catch((error)=>{
      toast.error('Something went wrong!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    })
  },[goodId])


  return (
    <>
        { isLoading && <Loading></Loading> }
        <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
        />
      <div className='px-3 py-1 table-responsive'>
        <div className='w-100 d-flex align-items-center justify-content-between mb-3'>
            <h5 className='fw-bold mb-0'>Tax List</h5>
        </div>
        <table className='table'>
          <thead className='align-middle'>
              <tr>
                <th className={`bg-info py-3 text-center ${styles.firstHead}`}>#</th>
                <th className='bg-info py-3 text-center'>ID NO</th>
                <th className='bg-info py-3 text-center'>ID DATE</th>
                <th className={`bg-info py-3 text-center ${styles.lastHead}`}>ID QTY</th>
              </tr>
          </thead>
          <tbody>
            {
              taxs.length > 0 ?
              taxs.map((el,i)=>{
                return  <tr key={i}>
                      <td className='text-center'>{i+1}</td>
                      <td className='text-center'>{el.transaction_id}</td>
                      <td className='text-center'>{el.id_date}</td>
                      <td className='text-center'>{el.quantity}</td>
                    </tr>
              })
              :
              <tr>
                <td colSpan={14} className='p-0 border-0'>
                    <div className="alert alert-warning text-center mb-0" role="alert">
                        There is no record for invalid licenses!
                    </div>
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    </>
  )
}

export default TaxTable