import React,{useEffect, useState, createRef} from 'react'
import Breadcrumb from '../../components/common/Breadcrumb'
import Table from "../../components/invalidLicenses/Table"
import * as Icons from "react-icons/fa";
import Nav from '../../components/common/Nav';
import { ToastContainer, toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import { debounce } from 'lodash';

const InvalidLicense = () => {
  
  const [ startDate,setStartDate ] = useState(null);
  const [ endDate,setEndDate ] = useState(null);
  const [ searchText,setSearchText ] = useState("");
  const searchTextRef = createRef();

  const refresh = ()=>{
    if(localStorage.getItem('invalidPageNo')){
      localStorage.removeItem('invalidPageNo')
    }
    window.location.reload();
  }

  window.addEventListener('beforeunload',()=>{
    if(localStorage.getItem('invalidPageNo')){
      localStorage.removeItem('invalidPageNo')
    }
  })

  const changeDateFormat = (inputDate)=>{
    const toChange = new Date(inputDate);
    const year = toChange.getFullYear();
    const month = String(toChange.getMonth() + 1).padStart(2, '0');
    const day = String(toChange.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
}
  const filterByDate = (start,end)=>{
    return <Table startDate={ start !== null ? changeDateFormat(start): null } endDate={end !== null ? changeDateFormat(end): null} searchText={searchText} />;
  }

  const handleOnChange = debounce((text)=>{
    setSearchText(text);
  },1000)

  useEffect(() => {
    handleOnChange(searchText);
    return () => handleOnChange.cancel();
}, [searchText]);


  useEffect(()=>{
    if(localStorage.getItem('enterAfterLogin')){
      toast.success('Login successful', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
    localStorage.removeItem('enterAfterLogin');
    localStorage.removeItem('validPageNo')
    },[])

    useEffect(() => {
      if( startDate && endDate ) {
        filterByDate(startDate, endDate, searchText);
      }
    }, [startDate, endDate, searchText]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Nav></Nav>
      <div className='col-12 p-3 border-0 bg-white'>
      <div className='d-flex justify-content-between align-items-center mb-3'>
        <div>
          <h4 className='text-start fw-bold'>Total Invalid Licenses</h4>
          <Breadcrumb prev={""}></Breadcrumb>
        </div>
        <div className='d-flex justify-content-between'>
          <div className='d-flex'>
            <div className='d-flex align-items-center'>
              <div className='me-2'>
                <DatePicker className='form-control border-primary' showYearDropdown dateFormat="yyyy-MM-dd" isClearable placeholderText='Start Date' selected={startDate} onChange={(date) => { setStartDate(date)}} />
              </div>
              <div className='me-2'>
                <DatePicker className='form-control border-primary' showYearDropdown dateFormat="yyyy-MM-dd" isClearable placeholderText='End Date' selected={endDate} onChange={(date) => { setEndDate(date)}} />
              </div>
            </div>
            <input ref={searchTextRef} onChange={(e)=>{handleOnChange(e.target.value)}} placeholder='License-no, company name and description' className="form-control me-2 border-primary" name='searchText' type="search" aria-label="Search"/>
            <button className='btn btn-outline-primary me-2' onClick={()=>{ refresh() }}>Refresh</button>
          </div>
        </div>
      </div>
      <div className="row px-2">
        {
          filterByDate(startDate, endDate, searchText)
        }
      </div>
      <div className='position-fixed bottom-0 end-0 pe-3 pb-5 mb-2'>
        <a href='#' className='btn btn-dark rounded-3 text-white'>
          <Icons.FaArrowUp></Icons.FaArrowUp>
        </a>
      </div>
    </div>
    </>
  )
}

export default InvalidLicense