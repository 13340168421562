import React, { useEffect, useState } from 'react'
import styles from './taxTable.module.css'
import { useTaxByGoodIdMutation,useDeleteTaxMutation,useGetTaxByGoodIdQuery } from '../../../features/APIs/taxApi'
import SweetAlert from 'react-bootstrap-sweetalert';
import { FaPlusCircle } from "react-icons/fa";
import EditTransModal from './EditTransModal';
import Loading from '../../common/Loading';
import { ToastContainer, toast } from 'react-toastify';

const TaxTable = ({ goodId }) => {
  const [deleteTax,{ isLoading: delLoading, isSuccess: delSuccess}] = useDeleteTaxMutation();
  const [taxs,setTaxs] = useState([]);
  // const [ getTaxs, { isLoading: getTaxLoading }] = useTaxByGoodIdMutation();
  const { data: getTaxs, isFetching: getTaxLoading } = useGetTaxByGoodIdQuery(goodId);
  const [ showAlert,setShowAlert ] = useState(false);
  const [ idToDelete,setIdToDelete] = useState(null);
  const [taxId,setTaxId] = useState(null);
  const user = JSON.parse(localStorage.getItem('authToken')).user;

  const handleEdit = (taxId)=>{
    setTaxId(taxId);
  }

  const handleDelete = (id)=>{
    setShowAlert(true);
    setIdToDelete(id);
  }

  const confirmForDelete = async ()=>{
    deleteTax(idToDelete);
    setShowAlert(true);
    await cancelForDelete();
  }

  const cancelForDelete = ()=>{
    setIdToDelete(null);
    setShowAlert(false);
  }

  // const fetchTaxData = () => {
  //   getTaxs(goodId).unwrap()
  //     .then((data)=>{
  //       setTaxs(data.data)
  //       if(data.data.length > 0){
  //         setTaxId(data.data[0].id);
  //       }
  //     })
  //     .catch((error)=>{
  //       toast.error("Something went wrong", {
  //         position: "top-right",
  //         autoClose: 3000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "light",
  //       });
  //     })
  // };
  

  // useEffect(() => {
  //   if(goodId){
  //     fetchTaxData();
  //   }
  // }, [goodId,delSuccess])

  useEffect(()=>{
    if(getTaxs){
      setTaxs(getTaxs.data)
        if(getTaxs.data.length > 0){
          setTaxId(getTaxs.data[0].id);
        }
    }
  },[getTaxs])


  useEffect(()=>{
    if(delSuccess){
      toast.success('Successfully deleted.', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
      });
  }
  },[delSuccess])


  return (
    <>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />      
      {
          showAlert &&
            <SweetAlert
              showCancel
              confirmBtnText="Yes, delete it!"
              confirmBtnBsStyle="danger"
              title="Are you sure?"
              onConfirm={confirmForDelete}
              onCancel={cancelForDelete}
              focusCancelBtn
            >
            You want to delete this tax record!
            </SweetAlert>
        }
        {
                  delLoading || getTaxLoading ?
                  <Loading></Loading>:
                  <div className='px-3 py-1 table-responsive'>
                  <div className='w-100 d-flex align-items-center justify-content-between mb-3'>
                      <h5 className='fw-bold mb-0'>Tax List</h5>
                      {
                        user.is_admin == 1 &&
                        <button className='btn btn-primary text-white d-flex align-items-center' data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                          <FaPlusCircle className='me-2'></FaPlusCircle>
                          Create Tax
                        </button>
                      }
                  </div>
                  {
                    taxId &&
                    <EditTransModal goodId={goodId} taxId={taxId}></EditTransModal>
                  }
                  <table className='table'>
                    <thead className='align-middle'>
                        <tr>
                        <th className={`bg-info py-3 text-center ${styles.firstHead}`}>#</th>
                        <th className='bg-info py-3 text-center'>ID NO</th>
                        <th className='bg-info py-3 text-center'>ID DATE</th>
                        <th className='bg-info py-3 text-center'>ID QTY</th>
                        {
                          user.is_admin == 1 &&
                          <th className={`bg-info py-3 text-center ${styles.lastHead}`}>ACTIONS</th>
                        }
                        </tr>
                    </thead>
                    <tbody>
                      {
                        taxs.length > 0 ?
                        taxs.map((el,i)=>{
                          return  <tr key={i}>
                                <td className='text-center'>{i+1}</td>
                                <td className='text-center'>{el.transaction_id}</td>
                                <td className='text-center'>{el.id_date}</td>
                                <td className='text-center'>{el.quantity}</td>
                                {
                                  user.is_admin == 1 &&
                                  <td className='text-center'>
                                    <button className='btn btn-warning btn-sm text-white me-2' data-bs-toggle="modal" data-bs-target="#editModal" onClick={()=>{handleEdit(el.id)}}>Edit</button>
                                    <button className='btn btn-danger btn-sm text-white' onClick={()=>{handleDelete(el.id)}}>Delete</button>
                                  </td>
                                }
                              </tr>
                        })
                        :
                        <tr>
                          <td colSpan={14} className='p-0 border-0'>
                              <div className="alert alert-warning text-center mb-0" role="alert">
                                  There is no record for valid licenses!
                              </div>
                          </td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
        }
    </>
  )
}

export default TaxTable