import { createContext,useState,useEffect } from "react";
import Axios from 'axios'
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import Loading from "../components/common/Loading";


const AuthContext = createContext();

export default AuthContext

export const AuthProvider = ({children})=>{

    const nav = useNavigate();
    const expiredTime = 3500 * 1000;
    const [ token,setToken ] = useState(()=>localStorage.getItem("authToken") ? JSON.parse(localStorage.getItem("authToken")) : null)
    const [ user,setUser ] = useState(()=>localStorage.getItem("authToken") ? JSON.parse(localStorage.getItem("authToken")).user : null)
    const [loading,setLoading] = useState(false);

    let login = async (loginData)=>{
        setLoading(true);
        try {
            let response = await Axios.post(
            "https://sdfapi.rcs-mm.com/api/auth/login",
              loginData,
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );
        
            let data = response.data;
            if (response.status === 200) {
                setLoading(false);
                setToken(data);
                setUser(data.user);
                const currentTime = Math.floor(Date.now() / 1000);
                localStorage.setItem("authToken", JSON.stringify({...data,...{currentTime: currentTime}}));
                localStorage.setItem("enterAfterLogin", true);
                nav("/");
            }
          } catch (error) {
            setLoading(false);
            console.log(error);
            if(error.response?.data.response.status){
              toast.error("Missing email or password!", {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
            }else{
              toast.error(error.message, {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
            }
          }
    }

    const logOut = ()=>{
        setToken(null);
        setUser(null);
        localStorage.removeItem('authToken')
        nav('/login')
    }

    const updateToken = async ()=>{
        const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + JSON.parse(localStorage.getItem('authToken')).access_token,
        };
        try {
            const response = await Axios.post(
            "https://sdfapi.rcs-mm.com/api/auth/refresh",
              null,
              { headers }
            );
        
            let data = await response.data;
            if (response?.status === 200) {
              setToken(data.access_token);
              setUser(data.user);
              const currentTime = Math.floor(Date.now() / 1000);
              localStorage.setItem("authToken", JSON.stringify({...data,...{currentTime: currentTime}}));
            }else{
              logOut();
            }
          } catch (error) {
              if(error.response.status === 401){
                await toast.error("Unauthorized, please login again!", {
                  position: 'top-right',
                  autoClose: 1000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'light',
                });
              }else{
                await toast.error("Something went wrong!", {
                  position: 'top-right',
                  autoClose: 1000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'light',
                });
              }
            logOut()
          }
      }


      function isAuthTokenExpired() {
        const authTokenCreationTime = JSON.parse(localStorage.getItem('authToken')).currentTime;
        if (!authTokenCreationTime) {
          return true;
        }
        const currentTime = Math.floor(Date.now() / 1000);
        const timeDifference = currentTime - authTokenCreationTime;
        return timeDifference > 3600;
      }


    const forgotPassword = async(email)=>{
        setLoading(true);
        try {
            let response = await Axios.post(
            "https://sdfapi.rcs-mm.com/api/auth/forgot-password",
              email,
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );
            let data = response.data;
            if (response.status === 200) {
                setLoading(false);
                nav("/reset-password-link");
            }
          } catch (error) {
            await toast.error(error.response.data.response.message, {
                position: 'top-right',
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
            setTimeout(()=>{
                window.location.reload()
            },1000)
          }
    }

    const resetPassword = async (resetData,oneTimeToken)=>{
        setLoading(true);
        try{
          Axios.get(`https://sdfapi.rcs-mm.com/api/auth/verify-reset-password-token/${oneTimeToken}`)
          .then((res)=>{
            if(res.data){
              updatePassword({...resetData,...{ email: res.data.data.email}},oneTimeToken)
            }
          })
          }catch(error) {
            console.log(error);
          }       
    }

    const updatePassword = async (newData,oneTimeToken)=>{
      try {
        let response = await Axios.put(
        "https://sdfapi.rcs-mm.com/api/auth/reset-password",
        newData,
          {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + oneTimeToken,
            },
          }
        );
    
        let data = response.data;
        if (response.status === 200) {
            setLoading(false);
            await toast.success('Your password has been changed', {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
            nav("/login");
        }
      } catch(error) {
        console.log(error);
      }
    }

    const contextData ={
        user: user,
        login: login,
        logOut: logOut,
        forgotPassword: forgotPassword,
        resetPassword: resetPassword,
        loading: loading,
        isAuthTokenExpired: isAuthTokenExpired
    }

    useEffect(()=>{
        let interval = setInterval(()=>{
            if(token){
                updateToken()
            }
        },expiredTime)
        return ()=> clearInterval(interval)
    },[token])

    return (
        <AuthContext.Provider value={contextData}>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
           { loading ? 
              <Loading></Loading>
            : 
                <>
                {children}
                </>
            }
        </AuthContext.Provider>
    )
}