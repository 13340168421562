import React,{useState,useRef, useEffect} from 'react'
import { Link,useNavigate } from 'react-router-dom'
import Breadcrumb from '../../components/common/Breadcrumb'
import Nav from '../../components/common/Nav'
import * as Icons from 'react-icons/fa'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loading from "../../components/common/Loading"
import { useAddLicenseMutation } from '../../features/APIs/validLicensesApi'
import { ToastContainer, toast } from 'react-toastify';

const CreateLicense = () => {
  const nav = useNavigate();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [invalidDate, setInvalidDate] = useState(null);
  const formRef = useRef();
  const [addLicense,{ isLoading, isSuccess, data, error }] = useAddLicenseMutation();
  const [ isEmpty,setIsEmpty] = useState(true);



  const good = 
  { 
    id: Date.now(),
    name: "",
    hs_code: "",
    maccs_code: "",
    price: "",
    quantity: "",
    unit: "Select Unit"
  };

  const checkIsEmpty = ()=>{
    return (
    goods.some(good => (
      good.name === '' ||
      good.hs_code === '' ||
      good.maccs_code === '' ||
      good.price === '' ||
      good.quantity === '' ||
      good.unit === 'Select Unit'
    )))
  }

  const changeDateFormat = (inputDate)=>{
    const toChange = new Date(inputDate);
    const year = toChange.getFullYear();
    const month = String(toChange.getMonth() + 1).padStart(2, '0');
    const day = String(toChange.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }
  
  const [goods,setGoods] = useState([good]);

  const handleInputChange = (e,index)=>{
    const { name, value } = e.target;
    const list = [...goods];
    list[index] = { ...list[index], [name]: value };
    setGoods(list);
    setIsEmpty(checkIsEmpty)
  }


  const handleAddClick = ()=>{
    setGoods([...goods,good]);
  }
  const handleRemoveClick = (i)=>{
    const list = [...goods];
    list.splice(i,1);
    setGoods(list);
  }

  const onSubmit = (e)=>{
    e.preventDefault();
    const form = formRef.current;
    const { company_name, license_no} = form;
    if(
      company_name?.value === '' ||
      license_no?.value === '' ||
      startDate === null ||
      endDate === null ||
      invalidDate === null ||
      goods.some(good => (
        good.name === '' ||
        good.hs_code === '' ||
        good.maccs_code === '' ||
        good.price === '' ||
        good.quantity === '' ||
        good.unit === 'Select Unit'
      ))
    ){
      toast.error('License creating failed!', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      return;
    }

    const formattedStartDate = changeDateFormat(startDate);
    const formattedEndDate = changeDateFormat(endDate);
    const formattedInvalidDate = changeDateFormat(invalidDate);

    const data = {
      company_name: company_name.value,
      license_no: license_no.value,
      license_start_date: formattedStartDate,
      license_expired_date: formattedEndDate,
      license_invalid_date: formattedInvalidDate,
      good: goods
    };
    addLicense(data);
    setGoods([good]);
  }

  useEffect(() => {
    setIsEmpty(checkIsEmpty);
    if (isSuccess) {
      formRef.current.reset();
      setStartDate(null);
      setEndDate(null);
      setInvalidDate(null);
      toast.success('New License is successfully created.', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      nav('/')
    }else if(error){
      toast.error('License creating failed!', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      window.location.reload();
    }
  }, [isSuccess,goods,data,error]);

  useEffect(()=>{
    localStorage.removeItem('validPageNo');
  },[])



  return (
    <>
      {
        isLoading && <Loading></Loading>
      }
      <Nav></Nav>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        />
      <form ref={formRef} onSubmit={(e)=>{onSubmit(e)}} className='col-12 p-3 border-0 bg-white createContainer'>
      <div className='d-flex justify-content-between align-items-center'>
        <div>
          <h3 className='text-start fw-bold'>Total Valid Licenses</h3>
          <Breadcrumb prev={"valid-licenses"}></Breadcrumb>
        </div>
      </div>
      <div className="row px-5">
        <div className='col-12 col-md-4 col-lg-3'>
          <div className=''>
            <label className='fw-bold mb-3'>Company Name</label>
            <input className='form-control' name='company_name' autoComplete='off'></input>
          </div>
          <small className='text-danger'></small>
        </div>
        <div className='col-12 col-md-4 col-lg-3'>
          <div className=''>
            <label className='fw-bold mb-3'>License No</label>
            <input className='form-control' name='license_no' autoComplete='off'></input>
            <small className='text-danger'></small>
          </div>
        </div>
        <div className='col-12 col-md-4 col-lg-2'>
          <div className=''>
            <label className='fw-bold mb-3'>License Start Date:</label>
            <DatePicker className='form-control'
             autoComplete='off' 
              dateFormat="yyyy-MM-dd" isClearable showYearDropdown
              name='license_start_date'
              selected={startDate}
              onChange={
              (date) =>{
                setStartDate(date);
              }}
            />
            <small className='text-danger'></small>
          </div>
        </div>
        <div className='col-12 col-md-4 col-lg-2'>
        <div className=''>
            <label className='fw-bold mb-3'>License Expired Date:</label>
            <DatePicker
             autoComplete='off'
            className='form-control' 
            dateFormat="yyyy-MM-dd" isClearable showYearDropdown
            name='license_expired_date'
            selected={endDate}
            onChange={
              (date) =>{
                setEndDate(date);
              }}
            />
            <small className='text-danger'></small>
          </div>
        </div>
        <div className='col-12 col-md-4 col-lg-2'>
        <div className=''>
            <label className='fw-bold mb-3'>License Invalid Date:</label>
            <DatePicker
            autoComplete='off'
            className='form-control' 
            dateFormat="yyyy-MM-dd" isClearable showYearDropdown
            name='license_invalid_date'
            selected={invalidDate}
            onChange={
              (date) =>{
              setInvalidDate(date);
            }}
            />
            <small className='text-danger'></small>
          </div>
        </div>
      </div>

      {
        goods.map((el,i)=>{
          return (
          <div key={el.id} className='dOfGoodContainer'>
              <div className='dOfGood row mx-3 px-3 bg-secondary my-4 pt-3 pb-5 position-relative'>
            <div className='col-12 col-lg-6'>
              <div className=''>
                <label className='fw-bold mb-3'>Description Of Goods</label>
                <textarea  autoComplete='off' onChange={(e)=>{handleInputChange(e,i)}} rows={5} className='form-control' name='name'></textarea>
                <small className='text-danger'></small>
                </div>
              </div>
              <div className='col-12 col-md-6 d-flex flex-column justify-content-around'>
              <div className='row'>
                <div className='col-12 col-lg-6'>
                  <label className='fw-bold mb-3'>HS Code</label>
                  <input autoComplete='off' onChange={(e)=>{handleInputChange(e,i)}} className='form-control' name='hs_code'></input>
                  <small className='text-danger'></small>
                </div>
                <div className='col-12 col-lg-6'>
                  <label className='fw-bold mb-3 text-nowrap'>MACCS Code</label>
                  <input autoComplete='off' onChange={(e)=>{handleInputChange(e,i)}} className='form-control' name='maccs_code'></input>
                  <small className='text-danger'></small>
              </div>
              </div>
              <div className='row'>
                <div className='col-12 col-lg-4'>
                  <label className='fw-bold mb-3'>Price</label>
                  <input type='number' autoComplete='off' onChange={(e)=>{handleInputChange(e,i)}} className='form-control' name='price'></input>
                  <small className='text-danger'></small>
                </div>
                <div className='col-12 col-lg-4'>
                  <label className='fw-bold mb-3 text-nowrap'>Quantity</label>
                  <input autoComplete='off' type='number' onChange={(e)=>{handleInputChange(e,i)}} className='form-control' name='quantity'></input>
                  <small className='text-danger'></small>
                </div>
                <div className='col-12 col-lg-4'>
                  <label className='fw-bold mb-3'>Units</label>
                  <select onChange={(e)=>{handleInputChange(e,i)}} className="form-select" aria-label="select example" name='unit'>
                    <option>Select Unit</option>
                    <option value="KG">KG</option>
                    <option value="U">U</option>
                    <option value="PR">PR</option>
                  </select>
                  <small className='text-danger'></small>
                </div>
              </div>
            </div>
            {
              goods.length !== 1 &&
              <button onClick={()=>{handleRemoveClick(i)}} className='btn btn-danger text-white position-absolute top-0 end-0 rounded-0' style={{"width": "60px"}}><Icons.FaMinus></Icons.FaMinus></button>
            }
            {
              goods.length-1 === i &&
              <button
                onClick={()=>
                  {
                    handleAddClick()
                  }}
                className={`btn btn-success text-white position-absolute bottom-0 end-0 rounded-0`} style={{"width": "60px"}} disabled={isEmpty}><Icons.FaPlus></Icons.FaPlus></button>
            }
          </div>
          </div>)
        })
      }
      
      <div className='d-flex justify-content-end'>
        <Link to={"/"} className='btn btn-outline-dark btn-lg px-5 py-3 me-2'>Cancel</Link>
        <button className='btn btn-primary text-white btn-lg px-5 py-3'>Save</button>
      </div>
      </form>
    </>

  )
}
export default CreateLicense