import React from 'react';
import { Routes,Route} from 'react-router-dom'
import { AuthProvider } from './context/AuthContext';
import { Provider } from 'react-redux'
import RequireAuth from './utils/RequireAuth';
//css
import './App.css';
import Login from './pages/auth/Login'
import ForgotPassword from './pages/auth/ForgotPassword';
import ChangePassword from './pages/auth/ChangePassword';
import LinkSent from './pages/auth/LinkSent';

import InvalidLicense from './pages/invalidLicense/InvalidLicense'
import ValidLicense from './pages/validLicense/ValidLicense';
import Setting from './pages/Setting';
import CreateLicense from './pages/validLicense/CreateLicense';
import EditLicense from './pages/validLicense/EditLicense';
import LicenseDetail from './pages/validLicense/LicenseDetail';
import LicenseDetailInvalid from './pages/invalidLicense/LicenseDetail';
import Home from './pages/Home';
import NotFound from './components/common/NotFound';

function App() {


  return (
    <AuthProvider>
      <div className="App">
      <div className='container-fluid'>
        <div className='row'>
          <Routes>
            <Route path='/login' element={<Login/>}></Route>
            <Route path='/forgot-password' element={<ForgotPassword/>}></Route>
            {/* <Route path='/reset-password/:oneTimeToken' element={<ChangePassword></ChangePassword>}></Route> */}
            <Route path='/reset-password/:oneTimeToken' element={<ChangePassword/>}></Route>
            <Route path='/reset-password-link' element={<LinkSent/>}></Route>
            <Route path='/' element={<RequireAuth>
              <Home/>
            </RequireAuth>}></Route>
            <Route path='/valid-licenses' element={<RequireAuth>
              <ValidLicense/>
            </RequireAuth>}></Route>
            <Route path='/create' element={<RequireAuth>
              <CreateLicense/>
            </RequireAuth>}></Route>
            <Route path='/edit/:id' element={<RequireAuth>
              <EditLicense/>
            </RequireAuth>}></Route>
            <Route path='/valid-licenses-detail/:id' element={<RequireAuth>
              <LicenseDetail/>
            </RequireAuth>}></Route>
            <Route path='/invalid-licenses-detail/:id' element={<RequireAuth>
              <LicenseDetailInvalid/>
            </RequireAuth>}></Route>
            <Route path='/invalid-licenses' element={<RequireAuth>
              <InvalidLicense/>
            </RequireAuth>}></Route>
            <Route path='/setting' element={<RequireAuth>
              <Setting/>
            </RequireAuth>}></Route>
            <Route path='/*' element={<NotFound/>}></Route>
          </Routes>
        </div>
      </div>
      </div>
    </AuthProvider>
  );
}

export default App;
