import React,{createRef, useEffect} from 'react'
import { FaUserCog } from 'react-icons/fa';
import { useAddUserMutation } from '../../features/APIs/settingApi';
import { ToastContainer, toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"
import Loading from "../common/Loading"



const CreateUserCard = () => {

    const formRef = createRef();
    const [addUser,{error,isError,isLoading,isSuccess}] = useAddUserMutation();


    const userSchema = yup.object().shape({
        name: yup.string().required("Username is required!"),
        email: yup.string().email("Email is invalid!").required('Email is required'),
        password: yup.string().min(6,"Password must have at least 6 characters.").required(),
        is_admin: yup.string().required("Role must be selected!")
    })

    const { register,handleSubmit,formState: {errors}} = useForm({
        resolver : yupResolver(userSchema),
    })
    
    const onSubmit = (data)=>{
        addUser(data);
        formRef.current.reset();
    }

    useEffect(()=>{
        if(isSuccess){
            toast.success('New user is successfully created.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }else if(error){
            toast.error('Something went wring!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    },[isSuccess,error])

  return (
        <>
            <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            />
            {
                isLoading&&
                <Loading></Loading>
            }
            <div className='col-12 col-lg-6 col-xl-4 mt-3'>
                <div className='card p-3 shadow-sm' style={{"minHeight":"350px"}}>
                    <div className='d-flex align-items-start position-sticky top-0'>
                        <FaUserCog className='fs-4'></FaUserCog>
                        <h5 className='card-title fw-bold ms-2'>Create User Account</h5>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} ref={formRef} className='card-body p-0'>
                        <div className="row mt-3">
                            <label htmlFor="username" className="col-sm-4 col-form-label fw-bold fs-16">Username</label>
                            <div className="col-sm-8">
                                <input {...register('name')} type="text" className="form-control" id="username"/>
                                <small className='text-danger'>{errors.name?.message}</small>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <label htmlFor="email" className="col-sm-4 col-form-label fw-bold fs-16">Email</label>
                            <div className="col-sm-8">
                                <input {...register('email')} type="text" className="form-control" id="email"/>
                                <small className='text-danger'>{errors.email?.message}</small>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <label htmlFor="password" className="col-sm-4 col-form-label fw-bold fs-16">Password</label>
                            <div className="col-sm-8">
                                <input type="password" {...register('password')} className="form-control" id="inputPassword"/>
                                <small className='text-danger'>{errors.password?.message}</small>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <label htmlFor="password" className="col-sm-4 col-form-label fw-bold fs-16">Type Of User</label>
                            <div className="col-sm-8">
                                <div className='d-flex justify-content-between'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" value={"1"} {...register("is_admin")} id="flexRadioDefault1"/>
                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                            Admin
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" value={"0"} {...register("is_admin")} id="flexRadioDefault2" />
                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                            Normal User
                                        </label>
                                    </div>
                                </div>
                                <small className='text-danger'>{errors.is_admin?.message}</small>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-8 d-flex justify-content-between ms-auto">
                            <button className='btn btn-primary text-white w-100'>Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>

  )
}

export default CreateUserCard