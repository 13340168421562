import { apiSlice } from "./apiSlice";


export const invalidLicensesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllInvalidLicenses: builder.query({
        query: ()=>({
            url: "/licenses?type=invalid",
            method: "GET"
        }),
        providesTags: ["invalid-licenses"],
    }),
    invalidLicenseByDate: builder.mutation({ // date filter
      query: ({ startDate,endDate })=>({
        url: `/licenses/invalid-between?start_date=${startDate}&end_date=${endDate}`,
        method: "GET"
      }),
      invalidatesTags: ['invalid-licenses']
    }),
    searchedByDateBetweenInvalid: builder.mutation({ // search between date filter
      query: ({startDate,endDate,searchText})=>({
        url: `/licenses/invalid-search-between?query=${searchText}&start_date=${startDate}&end_date=${endDate}`,
        method: "GET"
      }),
      invalidatesTags: ["invalid-licenses"]
    }),
    searchedDateBetweenByPageInvalid: builder.mutation({// search between date filter
      query: ({ startDate,endDate,searchText,pageNo})=>({
        url: `/licenses/invalid-search-between?query=${searchText}&start_date=${startDate}&end_date=${endDate}&page=${pageNo}`,
        method: "GET"
      }),
      invalidatesTags: ["invalid-licenses"]
    }),
    dateFilterByPageInvalid: builder.mutation({ //date filter pagination
      query: ({ startDate,endDate,pageNo })=>({
        url: `/licenses/invalid-between?start_date=${startDate}&end_date=${endDate}&page=${pageNo}`,
        method: "GET",
      }),
      invalidatesTags: ["invalid-licenses"]
    }),
    getLicensesByPageInvalid: builder.mutation({ //pagination for all valid licenses
      query: (pageNo)=>({
        url: `/licenses?type=invalid&page=${pageNo}`,
        method: "GET"
      }),
      invalidatesTags: ["invalid-licenses"]
    }),
    getLicenseBySearchInvalid: builder.mutation({//search
      query: (searchText)=>({
        url: `/licenses/invalid-search?query=${searchText}`,
        method: "GET"
      }),
      invalidatesTags: ['invalid-licenses']
    }),
    getSearchedLicensesByPageInvalid: builder.mutation({//searched licenses pagination
      query: ({ searchText,pageNo })=>({
        url: `/licenses/invalid-search?query=${searchText}&page=${pageNo}`,
        method: "GET",
      }),
      invalidatesTags: ["invalid-licenses"]
    }),
    deleteLicenseInvalid: builder.mutation({
      query: (id) => ({
        url: `/licenses/delete/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: ["invalid-licenses"],
    }),
  }),
});

export const {
  useGetAllInvalidLicensesQuery,
  useInvalidLicenseByDateMutation,
  useGetSearchedLicensesByPageInvalidMutation,
  useDateFilterByPageInvalidMutation,
  useGetLicensesByPageInvalidMutation,
  useGetLicenseBySearchInvalidMutation,
  useSearchedByDateBetweenInvalidMutation,
  useSearchedDateBetweenByPageInvalidMutation,
  useDeleteLicenseInvalidMutation
} = invalidLicensesApi;