import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useState, useEffect } from "react";

const getBearerToken = () => {
  const authToken = localStorage.getItem("authToken");
  return authToken ? `Bearer ${JSON.parse(authToken).access_token}` : "";
};

export const apiSlice = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: "https://sdfapi.rcs-mm.com/api",
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      headers.set("Authorization", getBearerToken());
      return headers;
    },
  }),
  tagTypes: ["valid-licenses","invalid-licenses", "transactions","settings"],
  endpoints: (builder) => ({}),
});

const MyComponent = () => {
  const [header, setHeader] = useState(getBearerToken());

  useEffect(() => {
    setHeader(getBearerToken());
  }, []);

  useEffect(() => {
    apiSlice.reducerPath = header;
    apiSlice.baseQuery.initiate();
  }, [header]);

  return null;
};
