import React from 'react'
import { Link,useLocation,useParams } from 'react-router-dom'
import styles from './breadcrumb.module.css'

const Breadcrumb = ({prev}) => {

    const path = useLocation().pathname;

  return (
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <Link to={`/${prev}`} className={`${styles.breadcrumbItem} breadcrumb-item text-decoration-none`}> {prev} </Link>
                <li className={`${styles.breadcrumbItem} breadcrumb-item text-primary`}>{path === "/" ? "valid-licenses" : path.split("").slice(1).join("")} </li>
            </ol>
        </nav>
  )
}

export default Breadcrumb