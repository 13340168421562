import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import DetailDOfGood from '../../components/validLicenses/DetailDOfGood'
import TaxTable from '../../components/validLicenses/Transaction/TaxTable'
import CreateTransModal from '../../components/validLicenses/Transaction/CreateTransModal';
import Breadcrumb from '../../components/common/Breadcrumb'
import { useGetSingleLicenseQuery } from '../../features/APIs/validLicensesApi'
import styles from '../validLicense/detail.module.css'
import Nav from '../../components/common/Nav';
import Loading from '../../components/common/Loading';


const LicenseDetail = () => {

  const id = useParams().id;
  const [ goodId,setGoodId ] = useState(null);
  const [ license,setLicense ] = useState({});
  const { data: getLicense, isLoading, isSuccess, refetch } = useGetSingleLicenseQuery(id, {
    refetchOnMountOrArgChange: true,
    pollingInterval: 1000,
  });


  const handleClick = (clickedId) => {
    setGoodId(clickedId);
  };

  useEffect(()=>{
    if(getLicense){
      setLicense(getLicense.data);
      setGoodId(getLicense.data.good[0].id)
    }
  },[getLicense])

  return (
    <>
        <Nav></Nav>
        <CreateTransModal goodId={goodId}></CreateTransModal>
        <div className='col-12 p-3 border-0 bg-white'>
          <div className='d-flex justify-content-between align-items-center'>
            <div>
              <h4 className='text-start fw-bold'>Total Valid Licenses</h4>
              <Breadcrumb prev={"valid-licenses"}></Breadcrumb>
            </div>
          </div>
          {
            isLoading ?
            <Loading></Loading>
            :
            <>
            <table className='table table-borderless w-25'>
              <tbody>
                <tr>
                  <td className={styles.heading}>License No</td>
                  <td className={styles.heading}>:</td>
                  <td className={`${styles.heading} text-primary`}>{license.license_no}</td>
                </tr>
                <tr>
                  <td className={`${styles.heading} text-nowrap`}>Company Name</td>
                  <td className={styles.heading}>:</td>
                  <td className={`${styles.heading} text-primary`}>{license.company_name}</td>
                </tr>
                <tr>
                  <td className={styles.heading}>License Date</td>
                  <td className={styles.heading}>:</td>
                  <td className={`${styles.heading} text-primary`}>{license.license_start_date}</td>
                </tr>
                <tr>
                  <td className={styles.heading}>Expired Date</td>
                  <td className={styles.heading}>:</td>
                  <td className={`${styles.heading} text-primary`}>{license.license_expired_date}</td>
                </tr>
                <tr>
                  <td className={styles.heading}>Invalid Date</td>
                  <td className={styles.heading}>:</td>
                  <td className={`${styles.heading} text-primary`}>{license.license_invalid_date}</td>
                </tr>
              </tbody>
            </table>
            {/* detail for description of goods */}
            <div className='bg-secondary h-100 mx-2 d-flex'>
              <div className='p-0'>
                <nav className="nav flex-column bg-white h-100">
                    {
                      license.good &&
                      license.good.map((el,i)=>
                        <span onClick={()=>{ handleClick(el.id) }} key={i} className={`nav-link text-black my-2 text-nowrap border-start border-2 ${ goodId === el.id ? "border-primary": "border-white" }`}>Description {i+1}</span>
                      )
                    }
                </nav>
              </div>
              <div className={`table-responsive ${styles.detailsDOfGood}`}>
                {
                  goodId &&
                  <DetailDOfGood id={id} goodId={goodId}></DetailDOfGood>
                }
                {
                  goodId &&
                  <TaxTable goodId={goodId}></TaxTable>
                }

              </div>
            </div>
            </>
          }
    </div>
    </>
  )
}

export default LicenseDetail