import React,{useEffect, useRef,useState, useContext} from 'react'
import { useParams,Link,useNavigate } from 'react-router-dom'
import Breadcrumb from '../../components/common/Breadcrumb'
import Nav from '../../components/common/Nav'
import * as Icons from 'react-icons/fa'
import Loading from "../../components/common/Loading"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import { useGetSingleLicenseQuery,useUpdateLicenseMutation,useDeleteGoodMutation } from '../../features/APIs/validLicensesApi'



const EditLicense = () => {


  const nav = useNavigate();
  const [updateLicense, { isLoading: updateLoading , isError: updateError }] = useUpdateLicenseMutation();
  const id = useParams().id;
  const { data: getLicense, isLoading, isSuccess, refetch } = useGetSingleLicenseQuery(id, {
    refetchOnMountOrArgChange: true,
  });
  const [ deleteGood , { isLoading: delLoading }] = useDeleteGoodMutation();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [invalidDate, setInvalidDate] = useState(null);
  const [ isEmpty,setIsEmpty] = useState(true);
  const formRef = useRef();
  const good = 
  { 
    // id: Date.now(),
    name: "",
    hs_code: "",
    maccs_code: "",
    price: "",
    quantity: "",
    unit: "KG"
  };
  const checkIsEmpty = (company_name,license_no)=>{
    return (
      company_name?.value === '' ||
      license_no?.value === '' ||
      startDate === null ||
      endDate === null ||
      invalidDate === null ||
      goods.some(good => (
        good.name === '' ||
        good.hs_code === '' ||
        good.maccs_code === '' ||
        good.price === '' ||
        good.quantity === ''
      ))
    )
  }

  const changeDateFormat = (inputDate)=>{
    const toChange = new Date(inputDate);
    const year = toChange.getFullYear();
    const month = String(toChange.getMonth() + 1).padStart(2, '0');
    const day = String(toChange.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }
  
  const [goods,setGoods] = useState([good]);
  const [license,setLicense] = useState({
    company_name: '',
    license_no: '',
    license_start_date: '',
    license_expired_date: '',
    license_invalid_date: '',
    good: goods
  });

  const handleInputChange = (e,index)=>{
    const { name, value } = e.target;
    const list = [...goods];
    list[index] = { ...list[index], [name]: value };
    setGoods(list);
    setIsEmpty(false);
  }
  const inputChange = ()=>{
    setIsEmpty(false);
  }

  const handleAddClick = ()=>{
    setGoods([...goods, good]);
    setIsEmpty(false);
  }
  const handleRemoveClick = (i,id)=>{
    const list = [...goods];
    list.splice(i,1);
    setGoods(list);
    setIsEmpty(false);
    if(id !== undefined){
      deleteGood(id).unwrap()
      .then(()=> console.log("success"))
      .catch((error)=>{ throw new Error(error) })
    }
  }

  const onSubmit = (e)=>{
    e.preventDefault();
    const form = formRef.current;
    const { company_name, license_no } = form;
    if (checkIsEmpty(company_name,license_no)) {
      toast.error('License updating failed!.',
      {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      return;
    }

    const formattedStartDate = changeDateFormat(startDate);
    const formattedEndDate = changeDateFormat(endDate);
    const formattedInvalidDate = changeDateFormat(invalidDate);

    const data = {
      company_name: company_name.value,
      license_no: license_no.value,
      license_start_date: formattedStartDate,
      license_expired_date: formattedEndDate,
      license_invalid_date: formattedInvalidDate,
      good: goods
    };
    updateLicense({ id: id , data:data })
    .unwrap()
    .then(async () => {
      await toast.success('Successfully updated.', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      refetch();
      nav("/");
      // setTimeout(() => {
      //   nav("/");
      // }, 2000);
    })
    .catch((error) => {
      toast.error('Something went wrong!', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    });
  }

  const getLicenseFunction = (getLicense)=>{
    setLicense({
      company_name: getLicense.company_name,
      license_no: getLicense.license_no,
      license_start_date: getLicense.license_start_date,
      license_expired_date: getLicense.license_expired_date,
      license_invalid_date: getLicense.license_invalid_date,
      good: getLicense.good,
    });
    setStartDate(new Date(getLicense.license_start_date));
    setEndDate(new Date(getLicense.license_expired_date));
    setInvalidDate(new Date(getLicense.license_invalid_date));
  }

  useEffect(()=>{
    if(getLicense){
      getLicenseFunction(getLicense.data)
      setGoods(getLicense.data.good);
    }
  },[getLicense, updateLicense])

  useEffect(()=>{
    refetch();
  },[goods])


  return (
    <>
      { (updateLoading || isLoading) && <Loading></Loading> }
      <Nav></Nav>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        />
      <form ref={formRef} onSubmit={(e)=>{onSubmit(e)}} className='col-12 p-3 border-0 bg-white createContainer'>
        <div className='d-flex justify-content-between align-items-center'>
          <div>
            <h3 className='text-start fw-bold'>Total Valid Licenses</h3>
            <Breadcrumb prev={"valid-licenses"}></Breadcrumb>
          </div>
        </div>
        <div className="row px-5">
          <div className='col-12 col-md-4 col-lg-3'>
            <div className=''>
              <label className='fw-bold mb-3'>Company Name</label>
              <input onChange={()=>{inputChange()}} className='form-control' name='company_name' defaultValue={license.company_name}></input>
            </div>
            <small className='text-danger'></small>
          </div>
          <div className='col-12 col-md-4 col-lg-3'>
            <div className=''>
              <label className='fw-bold mb-3'>License No</label>
              <input onChange={()=>{inputChange()}} className='form-control' name='license_no' defaultValue={license.license_no}></input>
              <small className='text-danger'></small>
            </div>
          </div>
          <div className='col-12 col-md-4 col-lg-2'>
            <div className=''>
              <label className='fw-bold mb-3'>License Date:</label>
              <DatePicker className='form-control'
                dateFormat="yyyy-MM-dd" isClearable showYearDropdown
                name='license_start_date'
                selected={startDate}
                onChange={
                  (date) =>{
                    setIsEmpty(false);
                    setStartDate(date);
              } } />
              <small className='text-danger'></small>
            </div>
          </div>
          <div className='col-12 col-md-4 col-lg-2'>
            <div className=''>
              <label className='fw-bold mb-3'>Expired Date:</label>
              <DatePicker className='form-control'
                dateFormat="yyyy-MM-dd" isClearable showYearDropdown
                name='license_expired_date'
                selected={endDate}
                onChange={
                  (date) =>{
                    setIsEmpty(false);
                    setEndDate(date);
              }} />
              <small className='text-danger'></small>
            </div>
          </div>
          <div className='col-12 col-md-4 col-lg-2'>
              <div className=''>
                <label className='fw-bold mb-3'>Invalid Date:</label>
                <DatePicker className='form-control'
                  dateFormat="yyyy-MM-dd" isClearable showYearDropdown
                  name='license_invalid_date'
                  selected={invalidDate}
                  onChange={
                    (date) =>{
                      setIsEmpty(false);
                      setInvalidDate(date);
                }} />
                <small className='text-danger'></small>
              </div>
          </div>
        </div>
        {
          goods.map((el,i)=>{
            return (<div key={el.id ? el.id : i} className='dOfGoodContainer'>
            <div className='dOfGood row mx-3 px-3 bg-secondary my-4 pt-3 pb-5 position-relative'>
              <div className='col-12 col-lg-6'>
                <div className=''>
                  <label className='fw-bold mb-3'>Description Of Goods</label>
                  <textarea onChange={(e)=>{handleInputChange(e,i)}} rows={5} className='form-control' name='name' defaultValue={el.name}></textarea>
                  <small className='text-danger'></small>
                </div>
              </div>
              <div className='col-12 col-md-6 d-flex flex-column justify-content-around'>
                <div className='row'>
                  <div className='col-12 col-lg-6'>
                    <label className='fw-bold mb-3'>HS Code</label>
                    <input onChange={(e)=>{handleInputChange(e,i)}} className='form-control' name='hs_code' defaultValue={el.hs_code}></input>
                    <small className='text-danger'></small>
                  </div>
                  <div className='col-12 col-lg-6'>
                    <label className='fw-bold mb-3 text-nowrap'>MACCS Code</label>
                    <input onChange={(e)=>{handleInputChange(e,i)}} className='form-control' name='maccs_code' defaultValue={el.maccs_code}></input>
                    <small className='text-danger'></small>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12 col-lg-4'>
                    <label className='fw-bold mb-3'>Price</label>
                    <input onChange={(e)=>{handleInputChange(e,i)}} className='form-control' name='price' defaultValue={el.price}></input>
                    <small className='text-danger'></small>
                  </div>
                  <div className='col-12 col-lg-4'>
                    <label className='fw-bold mb-3 text-nowrap'>License Balance</label>
                    <input onChange={(e)=>{handleInputChange(e,i)}} className='form-control' name='quantity' defaultValue={el.quantity}></input>
                    <small className='text-danger'></small>
                  </div>
                  <div className='col-12 col-lg-4'>
                    <label className='fw-bold mb-3'>Units</label>
                    <select onChange={(e)=>{handleInputChange(e,i)}} className="form-select" aria-label="select example" name='unit' defaultValue={el.unit}>
                      <option value="KG">KG</option>
                      <option value="U">U</option>
                      <option value="PR">PR</option>
                    </select>
                    <small className='text-danger'></small>
                  </div>
                </div>
              </div>
              {
                goods.length !== 1 &&
                <a onClick={()=>{handleRemoveClick(i,el.id)}} className='btn btn-danger text-white position-absolute top-0 end-0 rounded-0' style={{"width": "60px"}}><Icons.FaMinus></Icons.FaMinus></a>
              }
              {
                goods.length-1 === i &&
                <a onClick={()=>{handleAddClick()}} className='btn btn-success text-white position-absolute bottom-0 end-0 rounded-0' style={{"width": "60px"}}><Icons.FaPlus></Icons.FaPlus></a>
              }
            </div>
            </div>)
          })
        }
        
        <div className='d-flex justify-content-end'>
          <Link to={"/"} className='btn btn-outline-dark btn-lg px-5 py-3 me-2'>Cancel</Link>
          <button className='btn btn-primary text-white btn-lg px-5 py-3' disabled={isEmpty}>Save</button>
        </div>
      </form>
    </>
  )
}

export default EditLicense