import React, { Fragment, useEffect, useState } from 'react'
import Breadcrumb from '../components/common/Breadcrumb'
import Nav from '../components/common/Nav'
import CreateUserCard from '../components/settings/CreateUserCard'
import NotiCard from '../components/settings/NotiCard'
import AccountCard from '../components/settings/AccountCard'
import Table from '../components/settings/Table'

const Setting = () => {

  const authToken = JSON.parse(localStorage.getItem("authToken"));
  const is_admin = authToken.user.is_admin;

  useEffect(()=>{
    if(localStorage.getItem('validPageNo')){
      localStorage.removeItem('validPageNo')
    }else if(localStorage.getItem('invalidPageNo')){
      localStorage.removeItem('invalidPageNo')
    }
  },[])

    return (
      <>
        <Nav></Nav>
        <div className='col-12 p-3 border-0 bg-white'>
            <h4 className='text-start fw-bold'>Settings</h4>
            <Breadcrumb prev={""}></Breadcrumb>
            <div className="row">
              {
                is_admin == 1 &&
                <Fragment>
                  <CreateUserCard></CreateUserCard>
                  <NotiCard></NotiCard>
                </Fragment>
              }
              <AccountCard></AccountCard>
              {
                is_admin == 1 &&
                <Table></Table>
              }
            </div>
        </div>
      </>
    )
  
}

export default Setting